import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

type ScrollHidderProps = {
    $scrollBackground?: string
}

const Container = styled(Grid)`
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;

    display: flex;
    flex-wrap: nowrap;

    &::-webkit-scrollbar {
        display: none;
    }
`

const ScrollHidder = styled.div<ScrollHidderProps>`
    position: absolute;
    height: 10px;
    background-color: ${({ $scrollBackground, theme }) =>
        $scrollBackground ?? theme.colors.gray[50]};
    width: 100%;
    bottom: 0;
`

const Wrapper = styled.div`
    width: 100%;
    position: relative;
    padding-bottom: 10px;
`

export { Container, ScrollHidder, Wrapper }
