import * as Styled from './styles'
import { Department } from '../Department'
import { HorizontalScroll } from '@components/HorizontalScroll'
import { DepartmentSliderProps } from '../types'

export const DepartmentSliderMobileView = ({
    departments
}: DepartmentSliderProps): JSX.Element => {
    return departments ? (
        <HorizontalScroll>
            <Styled.Container>
                <Styled.SliderContainer>
                    {departments.map((department, index) => (
                        <Department
                            key={`${department.nome}${index}`}
                            department={department}
                        />
                    ))}
                </Styled.SliderContainer>
            </Styled.Container>
        </HorizontalScroll>
    ) : (
        <></>
    )
}
