import styled from 'styled-components'
import { Stack } from '@components/Stack'

export const Container = styled(Stack).attrs({
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    direction: 'row'
})`
    padding: 24px 0 24px 8px;
`

export const SliderContainer = styled(Stack).attrs({
    fullwidth: true,
    direction: 'row'
})``
