import React from 'react'
import * as Styled from './HorizontalScrollStyles'

type HorizontalScrollType = {
    children: React.ReactNode
    scrollBackground?: string
}

export const HorizontalScroll = ({
    children,
    scrollBackground
}: HorizontalScrollType): JSX.Element => {
    return (
        <Styled.Wrapper className="horizontalScrollWrapper">
            <Styled.Container>{children}</Styled.Container>
            <Styled.ScrollHidder $scrollBackground={scrollBackground} />
        </Styled.Wrapper>
    )
}
